$(function() {

	var disclosure;
	var warnStatement;
	var invagg;

	$("#warning-statement-au, #disclosure-statement-au, #disclosure-statement-nz, #warning-statement-nz, #warning-statement, #disclosure-statement,  #invest-check-text").each(function() {
	  var clicked_option = $(this);
	  $(this).fancybox({
			maxWidth	: 800,
			width		: '105%',
			autoSize	: true,
			tpl: {
			  	closeBtn: '<span class="popup-close icon-close blue-close about-close"></span>'
			},
			afterShow: function() {
				if(clicked_option.attr('id') == 'disclosure-statement'){
            		disclosure = 'clicked';
            	}else if(clicked_option.attr('id') == 'warning-statement'){
            		warnStatement = 'clicked';
            	}else if(clicked_option.attr('id') == 'invest-check-text'){
            		invagg = 'clicked';
            	}
            	//script for print button in fancybox
            	var content = $('.fancybox-inner');
			    $('.fancybox-wrap').on("click", ".fancy_print", function(){
			    	$(this).hide();
				    win = window.open();
				    self.focus();
				    win.document.open();
				    win.document.write('<'+'html'+'><'+'head'+'><'+'style'+'>');
				    win.document.write('body, td { font-family: Verdana; font-size: 10pt;}');
				    win.document.write('<'+'/'+'style'+'><'+'/'+'head'+'><'+'body'+'>');
				    win.document.write(content.html());
				    win.document.write('<'+'/'+'body'+'><'+'/'+'html'+'>');
				    win.document.close();
				    win.print();
				    win.close();
				    $(this).show();
			    });
	        },
	        afterClose: function() {
	        	if(clicked_option.attr('id') == 'invest-check-text'){
            		$("input[name='digital_signature_first_name']").focus();
	            }
			}
	  });
	});
	// show the popup if document is already read, after click on checkbox
	$('#disclosure-checkbox, #warning-statement-checkbox').click(function(){
		if ($(this).is(':checked'))
		{
			$(this).parent().children('.error-tooltip').remove();
		}
	});
});
