$(function() {
    /*
    |--------------------------------------------------------------------------
    | GLOBAL
    |--------------------------------------------------------------------------
    */

    // All instances select2 caches
    window.select2caches = {};
    
    // Default select2 options
    window.$optionsDefault = {
        escapeMarkup: function (markup) { return markup; }, 
        minimumInputLength: 3,
        multiple: true,
        templateResult: function (data) {
            if (!data.id) { return data.text; }
            
            // if(typeof data.image != 'undefined')
            //     var $data = $( '<span><img src="' + data.image + '" width="50" /> ' + data.text + '</span>');
            // else
            //     var $data = $( '<span>' + data.text + '</span>');
            if(typeof data.image != 'undefined')
                var $data = $( '<div class="select2-companies-options"><img src="' + data.image + '" width="50" /><span>' + data.text + '</span></div>');
            else
                var $data = $( '<div class="select2-companies-options"><span>' + data.text + '</div>');

            return $data;
        }
    };

    /*
    |--------------------------------------------------------------------------
    | SELEC 2 INSTANCES
    |--------------------------------------------------------------------------
    */  
   
    /**
     * Industries of interest
     */
    $(".industries-tags-style").select2($.extend({}, $optionsDefault, {
        tags: false,
        // createSearchChoice: function(term, data) {
        //     if ($(data).filter( function() {
        //         return this.text.localeCompare(term) === 0;
        //     }).length === 0) {
        //     return { id: term, text: term };
        //     }
        // }
        templateResult: function (data) { return data.text },
        data: [],
        query: function(query) {
            // Term is not set by default
            if (!query.hasOwnProperty("term")) {
                query.term = '';
            }
            cacheAjaxQuery(query, 'its', '/api/industries/' + query.term);
        }
    }));

    /**
     * External investments on profile
     */
    $(".profile-companies-tags-style").select2($.extend({}, $optionsDefault, {
        data: [],
        multiple: false,
        templateResult: function (data) {
            if (!data.id) { return data.text; }

            if(typeof data.image != 'undefined')
                var $data = $( '<div class="select2-companies-options"><img src="' + data.image + '" width="50" /><span>' + data.text + '</span><a href="/company/'+data.profile_slug+'" target="_blank">See Profile</a></div>');
            else
                var $data = $( '<div class="select2-companies-options"><span>' + data.text + '</span><a href="/company/'+data.profile_slug+'" target="_blank">See Profile</a></div>');


            var $links = $data.children('a')
                .on('mouseup', function (evt) {
                    // stop select2 hiding the menu on mouseup, which prevents click firing
                    evt.stopPropagation();
                });

            return $data;
        },
        query: function(query) {
            // Term is not set by default
            if (!query.hasOwnProperty("term")) {
                query.term = '';
            }
            if (query.term !== '')
                cacheAjaxQuery(query, 'pcts', '/api/companies/' + query.term, false, true);
        },
    }));

    /**
     * Company/syndicate/project team
     */
    $(".team-tags-style").select2($.extend({}, $optionsDefault, {
        data: [],
        query: function(query) {
            // Term is not set by default
            if (!query.hasOwnProperty("term")) {
                query.term = '';
            }
            if (query.term !== '')
                cacheAjaxQuery(query, 'team', '/api/users/' + query.term);
        },
        templateResult: function (data) {
            if (!data.id) { return data.text; }

            var $data = $( '<div class="select2-team-option"><span>' + data.text + '</span></div>');
            if(typeof data.image != 'undefined'){
                $data =  $data.prepend('<img src="' + data.image + '"/>');
            }
            return $data;
        }
    }));

    /**
     * Team user with option to invite other users
     */
    $(".team-tags-style.select-with-invite").select2($.extend({}, $optionsDefault, {
        data: [],
        query: function(query) {
            // Term is not set by default
            if (!query.hasOwnProperty("term")) {
                query.term = '';
            }
            if (query.term !== '')
                cacheAjaxQuery(query, 'team', '/api/users/' + query.term);
        },
        templateResult: function (data) {
            if (!data.id) { return data.text; }

            var $data = $( '<div class="select2-team-option"><span>' + data.text + '</span></div>');
            if(typeof data.image != 'undefined'){
                $data =  $data.prepend('<img src="' + data.image + '"/>');
            }
            return $data;
        },
        "language": {
           "noResults": function(){
               return "No Results Found, <a href='#' class='invite-team-user'>click here to invite new user</a>";
            }
        }
    }));

    /**
     * Add prefunder for the offer
     */
    $(".select-prefunder").select2($.extend({}, $optionsDefault, {
        data: [],
        minimumInputLength: 3,
        query: function(query) {
            // Term is not set by default
            if (!query.hasOwnProperty("term")) {
                query.term = '';
            }
            if (query.term !== '')
                cacheAjaxQuery(query, 'team', '/api/users/' + query.term, true);
        },
        multiple: false,
        selectOnBlur: true,
        selectOnClose: true,
        "language": {
            "noResults": function () {
                return false;
            }
        }
    })).on("select2:select", function (e) {
        var selected = e.params.data;
        if (typeof selected !== "undefined") {
           $("#pre-email").val(selected.contact);
           $("#pre-user").val(selected.id);
           $(".is-new-field").val(selected.id);
        }else{
            $("#pre-email").val('');
            $("#pre-user").val('');
            $(".is-new-field").val(false);
        }
    });

    /**
     * Syndicated offer company details
     */
    $(".company-syndicate-offer").select2($.extend({}, $optionsDefault, {
        data: [],
        multiple: false,
        query: function(query) {
            // Term is not set by default
            if (!query.hasOwnProperty("term")) {
                query.term = '';
            }
            if (query.term !== '')
                cacheAjaxQuery(query, 'pcts', '/api/companies/' + query.term + '/withsocial', false, true);
        }
    })).on("select2:select", function (e) {
        var selected = e.params.data;
        if (typeof selected !== "undefined") {
           $("#company_id_field").val(selected.id);
        }else{ 
            $("#company_id_field").val('');
        }
    });

    /**
     * Syndicated offer industries
     */
    $(".industries-dropdown").select2({
        multiple: true,
        minimumResultsForSearch: -1
    });
    /*
    |--------------------------------------------------------------------------
    | HELPER FUNCTIONS
    |--------------------------------------------------------------------------
    */  
   
   /**
    * Function used as a callback in select2.query method
    * 
    * @param  {obj} query
    * @param  {string} cacheId
    * @param  {string} path 
    * @return {void}
    */
    function cacheAjaxQuery(query, cacheId, path, tags, company) {
        tags = tags || false;
        company = company || false;

        // Set cache id for
        if (!window.select2caches[cacheId]) {
            window.select2caches[cacheId] = {};
        }

        var key = query.term;
        var cachedData = window.select2caches[cacheId][key];

        if (cachedData) {
            query.callback({results: cachedData});
            return;
        } else {
            $.ajax({
            url: path,
            dataType: 'JSON',
            type: 'GET',
            success: function(data) {
                // Show create new company link at the top
                if (company) {
                    data.unshift({ text : 'Can’t find the company? Click <a class="create-new-cmp" href="' + config.routes.create_company + '?redirect=' + window.location.pathname + '">here</a> to add a new company.'});
                }
                // Show option as tag
                if (tags) {
                    data.unshift({ id: key, text: key, newOption: true }); 
                }
                window.select2caches[cacheId][key] = data;
                query.callback({results: data});
            }
            })
        }
    }
});