/**
 * Validation plugin form instances
 */
$(function () {

    // Default GLOBAL settings for all validation setups
    window.settings = {
        // Enables debug mode. If true, the form is not submitted and certain errors are displayed on the console
        // debug: true,
        onfocusout: false,
        onkeyup: false,
        ignore: ':hidden',
        errorClass: 'error-tooltip',
        errorElement: 'span',
        highlight: function (element, errorClass, validClass) {
            // Prevent error classes on the fields
            $(element).removeClass(errorClass);
        },
        errorPlacement: function (error, element) {
            element.closest('.form-group, .sfg-field, .q-fg-input-cont, .up-api-formgroup').append(error);
        },
        submitHandler: function (form) {
            $('form input[type=submit]').attr('disabled', 'disabled').val('Please wait...');
            form.submit();
        },
        invalidHandler: function (event, validator) {
            // 'this' refers to the form
            var errors = validator.numberOfInvalids();

            // This has not been fully implemented
            if (errors) {
                var message = errors == 1
                    ? 'You missed 1 field. It has been highlighted'
                    : 'You missed ' + errors + ' fields. They have been highlighted';
                $("div.error span").html(message);
                $("div.error").show();
            } else {
                $("div.error").hide();
            }
        }
    };

    // Client side validation rules for manual registration form
    var manualRegistrationForm = $('#manual-registration-form').validate($.extend({}, settings, {
        rules: {
            first_name: {required: true, alphaCharWithSpaceHyphenQuotes: true},
            last_name: {required: true, alphaCharWithSpaceHyphenQuotes: true},
            email: {required: true, email: true},
            country_id: {required: true}
        }
    }));

    // Client side validation rules for the second step of social network registrtion
    var registerSocialSecondStep = $('#register-social-second-step').validate($.extend({}, settings, {
        rules: {
            digital_signature_first_name: {required: true, alphaCharWithSpaceHyphenQuotes: true},
            digital_signature_last_name: {required: true, alphaCharWithSpaceHyphenQuotes: true},
            country_id: {required: true},
            disclosure_statement: {required: true},
            warning_statement: {required: true},
            email: {required: true}
        }
    }));

    // Client side validation rules for the second step of social network registrtion
    var registerSocialSecondStep = $('#register-social-second-step').validate($.extend({}, settings, {
        rules: {
            digital_signature_first_name: {required: true, alphaCharWithSpaceHyphenQuotes: true},
            digital_signature_last_name: {required: true, alphaCharWithSpaceHyphenQuotes: true},
            country_id: {required: true},
            disclosure_statement: {required: true},
            warning_statement: {required: true}
        }
    }));

    // Client side validation rules for the second step of manual/invite registrtion
    var registerManualSecondStep = $('#register-manual-second-step').validate($.extend({}, settings, {
        rules: {
            digital_signature_first_name: {required: true, alphaCharWithSpaceHyphenQuotes: true},
            digital_signature_last_name: {required: true, alphaCharWithSpaceHyphenQuotes: true},
            password: {required: true, minlength: 6},
            password_confirmation: {required: true, equalTo: "#password"},
            disclosure_statement: {required: true,},
            warning_statement: {required: true}
        }
    }));

    // Client side validation rules for login form
    var loginForm = $('#login-form').validate($.extend({}, settings, {
        rules: {
            email: {required: true, email: true},
            password: {required: true, minlength: 6}
        },
        messages: {
            password: {
                minlength: "Incorrect password."
            }
        }
    }));

    // Client side validation rules for profile settings form
    var profileSettingsForm = $('#profile-settings-form').validate($.extend({}, settings, {
        rules: {
            first_name: {required: true, minlength: 2},
            last_name: {required: true, minlength: 2},
            avatar: {accept: 'image/*'},
            'social_links[facebook]': {urlFormat: true},
            'social_links[twitter]': {urlFormat: true},
            'social_links[linkedin]': {urlFormat: true},
            'social_links[angelist]': {urlFormat: true},
            'addresses[0][postcode]': {digits: true},
            'profile[telephone]': {telephone: true},
            'profile[contact_email]': {email: true},

            'profileaddress[street_name]': {required: true, alphaNumberWithSpaceHyphenDashCommaQuote: true},
            'profileaddress[street_number]': {alphaNumberWithSpaceHyphenDashCommaQuote: true},
            'profileaddress[suburb]': {required: true, alphaCharWithSpaceHyphenQuotes: true},
            'profileaddress[city]': {required: true, alphaCharWithSpaceHyphenQuotes: true},
            'profileaddress[state_territory]': {required: true, alphaCharWithSpaceHyphenQuotes: true},
            'profileaddress[suburb]': {required: true, alphaCharWithSpaceHyphenQuotes: true},
            'profileaddress[postcode]': {required: true},
        },
        messages: {
            'profileaddress[street_search]': {
                required: 'This field is required'
            },
            'profileaddress[street_number]': {
                required: 'Street number is required',
            },
            'profileaddress[street_name]': {
                required: 'Street name is required',
            },
            'profileaddress[street_type]': {
                required: 'Street type is required',
            },
            'profileaddress[suburb]': {
                required: 'Suburb is required',
            },
            'profileaddress[postcode]': {
                required: 'Postcode is required',
            },
        },
        submitHandler: function (form) {
            if ($.trim($("#country option:selected").text()) == "Australia" && $("input[name='autocomplete_used']").val() == "used") {
                $("input[name='autocomplete_used']").attr("value", "");
            }
            form.submit();
        }
    }));

    // Client side validation rules for reset password first form
    var resetFormSecond = $('#reset-password-first').validate($.extend({}, settings, {
        rules: {
            email: {required: true, email: true},
        }
    }));

    // Client side validation rules for reset password second form
    var resetFormFirst = $('#reset-password-second').validate($.extend({}, settings, {
        rules: {
            email: {required: true, email: true},
            password: {required: true, minlength: 6},
            password_confirmation: {
                required: function (element) {
                    return $('#password').val() != '';
                },
                equalTo: "#password"
            }
        }
    }));

    // Client side validation rules for change email and password form in settings
    var changeEmailPassword = $('#email-pass-form').validate($.extend({}, settings, {
        rules: {
            email: {required: true, email: true},
            current_password: {
                required: function (element) {
                    return $('#password').val() != '';
                }, minlength: 6
            },
            password: {
                required: function (element) {
                    return $('#current-password').val() != '';
                }, minlength: 6
            },
            password_confirmation: {
                required: function (element) {
                    return $('#password').val() != '';
                }, equalTo: "#password"
            }
        },
        messages: {
            current_password: {
                required: "The current password field is required when password is present"
            },
            password: {
                required: "The password field is required when current password is present"
            }
        }
    }, settings));

    /**
     * Client side validation rules for identity validation form (CLOUD CHECK)
     */
    var identityValidationForm = $('#identity-validation-form').validate($.extend({}, settings, {
        rules: {
            first_name: {required: true, alphaCharWithSpaceHyphenQuotes: true},
            middle_name: {alphaCharWithSpaceHyphenQuotes: true},
            last_name: {required: true, alphaCharWithSpaceHyphenQuotes: true},

            days: {required: true, digits: true, rangelength: [1, 2], range: [1, 31]},
            month: {required: true, digits: true, rangelength: [1, 2], range: [1, 12]},
            year: {required: true, digits: true, maxlength: 4, min: 1900, isYearAllowed: true},

            'profileaddress[street_name]': {required: true, alphaNumberWithSpaceHyphenDashCommaQuote: true},
            'profileaddress[street_number]': {alphaNumberWithSpaceHyphenDashCommaQuote: true},
            'profileaddress[suburb]': {required: true, alphaCharWithSpaceHyphenQuotes: true},
            'profileaddress[city]': {required: true, alphaCharWithSpaceHyphenQuotes: true},
            'profileaddress[state_territory]': {required: true, alphaCharWithSpaceHyphenQuotes: true},
            'profileaddress[suburb]': {required: true, alphaCharWithSpaceHyphenQuotes: true},
            'profileaddress[postcode]': {required: true, digits: true},

            driver_licence_number: {alphanumeric: true},
            driver_licence_version: {digits: true},
            plate_number: {alphanumeric: true},

            passport_number: {alphanumeric: true},
            passport_expiry_date: {dateFormat: true}
        }
    }));

    // Client side validation rules for account contact details form
    var accountantsContactDetailsForm = $('#accountants-contact-details-form').validate($.extend({}, settings, {
        rules: {
            first_name_accountants: {required: true, alphaCharWithSpaceHyphenDash: true},
            last_name_accountants: {required: true, alphaCharWithSpaceHyphenDash: true},
            email_accountants: {required: true, email: true},
            telephone_accountants: {required: true, digits: true}
        }
    }));

    // Client side validation for manual upload of the certificate
    var manualCertificateId = $('#manual-certificate-id').validate($.extend({}, settings, {
        rules: {
            manual_certificate: {required: true, extension: "jpeg,bmp,png,pdf,doc,docx,xlsx,xls,xlt,xltx,ppsx,pps,pptx,ppt"},
        }
    }));

    // Client side validation rules for company form
    var companyForm = $('#main-company-form').validate($.extend({}, settings, {
        errorClass: 'cp-error-tooltip',
        ignore: [],
        errorPlacement: function (error, element) {
            element.closest('.cp-form-group, .cp-fg-textarea-container').append(error);
        },
        rules: {
            name: {required: true, minlength: 2},
            'social_links[website]': {required: true, urlFormat: true},
            'annual_revenue': {required: true},
            'industries[]': {required: true},
            logo: {
                required: true,
                accept: 'image/png, image/jpeg, image,jpg'
            },
            description: {required: true, maxlength: 2000},
            'incorporation_date': {dateFormat: true},
            'addresses[postcode]': {digits: true},
            'social_links[rabble]': {urlFormat: true},
            'social_links[facebook]': {urlFormat: true},
            'social_links[twitter]': {urlFormat: true},
            'social_links[linkedin]': {urlFormat: true},
            'social_links[angelist]': {urlFormat: true},
            'social_links[instagram]': {urlFormat: true},
        },
        messages: {
            logo: {
                accept: 'Please provide either a png or jpeg file.'
            }
        },
        invalidHandler: function (event, validator) {
            // Reset tabs
            $('.tabs-control li a').removeClass('error').next().text(0);

            // Add new rules
            var errors = validator.numberOfInvalids();
            if (errors) {
                $.each(validator.errorMap, function (key, value) {
                    var tabId = $("[name='" + key + "']").closest('.tabs-control-tab').attr('id');
                    var $tabLink = $("a[href='#" + tabId + "']");
                    var noErrors = parseInt($tabLink.next().text(), 10);
                    $tabLink.addClass('error').next().html(noErrors + 1);
                });
            }
        }
    }));

    // Client side validation rules for syndicate form
    var syndicateForm = $('#main-syndicate-form').validate($.extend({}, settings, {
        errorClass: 'error-tooltip',
        ignore: [],
        errorPlacement: function (error, element) {
            element.closest('.cp-form-group, .so-editor-cont, .sfg-field').append(error);
        },
        onfocusout: function (element, event) {
            if ($(element).valid()) {
                removeErrorTabsOnOneFiledValidation($(element));
            }
        },
        rules: {
            name: {required: true, maxlength: 100, description: true},
            typical_carry: {required: true, range: [0, 25], maxDecimalsPoints: {decimals: 2}},
            investment_size: {required: true},
            'industries[]': {required: true},
            logo: {
                required: true,
                accept: 'image/png, image/jpeg, image,jpg'
            },
            private_syndicate: {required: true},
            description: {required: true},
            syndicate_type_id: {required: true},
            syndicate_type_child_id: {
                required: function (element) {
                    return $.trim($("#syndicate_type option:selected").text()) == "VC fund";
                }
            },
            team_member: {mandatoryMemberInASection: ['.is-cd-cont']},
        },
        messages: {
            logo: {
                accept: 'Please provide either a png or jpeg file.'
            }
        },
        invalidHandler: function (event, validator) {

            // Reset tabs
            $('.tabs-control li a').removeClass('error').next().text(0);
            var errors = validator.numberOfInvalids();
            if (errors) {
                var groupOfTabIds = [];
                $.each(validator.errorMap, function (key, value) {
                    var tabId = $("[name='" + key + "']").closest('.tabs-control-tab').attr('id');
                    groupOfTabIds.push(tabId);
                    var $tabLink = $("a[href='#" + tabId + "']");
                    var noErrors = parseInt($tabLink.next().text(), 10);
                    $tabLink.addClass('error').next().html(noErrors + 1);
                    $('html, body').animate({scrollTop: $("#main-container").offset().top}, 1000);
                });

                var firstTab = groupOfTabIds[0];

                // This will go to the tab with the first error
                $("a[href='#" + firstTab + "'").click();

            }
        }
    }));

    // Remove required rule for image if edit form on company or syndicate
    if ($('.company-selected-logo').length) {
        $('.company-logo-field').rules('remove', 'required');
    }
    $('.cp-fg-image-preview > img').on('change', function () {
        $('.company-logo-field').rules('remove', 'required');
    })

//Client side validation for investment vehicles
    var investmentVehicles = $('#create-investment-vehicle').validate($.extend({}, settings, {
        rules: {
            name: {required: true, description: true},
            company_number: {required_buss_comp_number_in_vehicles: {inputclass: '.bussines-number'}, digits: true},
            business_number: {required_buss_comp_number_in_vehicles: {inputclass: '.company-number'}, digits: true},
            'addresses[street_name]': {required: true, alphaNumberWithSpaceHyphenDashCommaQuote: true},
            'addresses[street_number]': {alphaNumberWithSpaceHyphenDashCommaQuote: true},
            'addresses[suburb]': {required: true, alphaCharWithSpaceHyphenQuotes: true},
            'addresses[city]': {required: true, alphaCharWithSpaceHyphenQuotes: true},
            'addresses[postcode]': {required: true, digits: true},
            'addresses[country_id]': {required: true},
            'addresses[state_territory]': {required: true, alphaCharWithSpaceHyphenQuotes: true},
            incorporation_date: {dateFormat: true},
            'social_links[website]': {urlFormat: true},
            trustee_company_name: {
                required: function (element) {
                    if ($('.smsf-type-vehicles').length == 1 && !$('#corporate_trustee-yes').is(":checked"))
                        return false;

                    return true;
                }, description: true
            },
            trust_deed_file: {
                required: function () {
                    if ($('.existed-file').length == 0)
                        return true;

                    return false;
                }, extension: "jpg,jpeg,bmp,png,pdf,doc,docx,xlsx,xls,xlt,xltx,ppsx,pps,pptx,ppt"
            },
        }
    }));

//Client side validation for invite user
    var inviteTeamUser = $('#invite-team-user-form').validate($.extend({}, settings, {
        rules: {
            invited_email: {required: true, email: true, check_email_for_invited_users: true},
        },
        submitHandler: function (form) {
            addInvitedUser($('.invited_email'), $(".team-tags-style:visible").data('role'), '.is-cm-tabs-content div:visible ');
            //form.submit();
        }
    }));

// Client side validation for external investments
    var externalInvestment = $('#profile-company-form').validate($.extend({}, settings, {
        rules: {
            profile_company: {required: true},
            amount: {required: true}
        },
        submitHandler: function (form) {
            $('form input[type=submit]').attr('disabled', 'disabled');
            form.submit();
        }
    }));

    /*=================================================================*/
    /* ADDITIONAL VALIDATION METHODS                                                                                                 */
    /*=================================================================*/

    /**
     *  Validation rule that catches characters other than alpha characters (lower and upper)
     *  numbers, spaces, hyphens or dashes, spaces and quotes
     */
    $.validator.addMethod("alphaNumberWithSpaceHyphenDashCommaQuote", function (value, element) {
        if (value === '') return true;
        return /^[\.\,\sa-zA-Z0-9\/_'"`-]+$/.test(value);
    }, "Must contain only letters, numbers, spaces and some of the following characters [ . , _ ' \" ` - \\ ]");

    /**
     *  Validation rule that catches characters other than alpha characters (lower and upper)
     *  numbers, spaces, hyphens or dashes, spaces and quotes
     */
    $.validator.addMethod("numberWithSpaceAndDash", function (value, element) {
        if (value === '') return true;
        return /^[\s0-9-]+$/.test(value);
    }, "Must contain only numbers, spaces and dash");

    /**
     *  Validation rule that catches characters other than alpha characters
     *  (lower and upper), spaces, hyphens/dashes and quotes
     */
    $.validator.addMethod("alphaCharWithSpaceHyphenQuotes", function (value, element) {
        if (value === '') return true;
        return /^[\sa-zA-Z\/_'"`-]+$/.test(value);
    }, "Must contain only letters, spaces, hyphens, slashes and quotes.");

    /**
     * Only 18+ users are allowed to validate their identity
     * Check if their year of birth is lower than (current year - 18)
     */
    $.validator.addMethod("isYearAllowed", function (value, element) {
        return value <= ((new Date()).getFullYear() - 18);
    }, "Invalid year.");

    /**
     * Dates should have this specific format: dd-mm-yyyy
     */
    $.validator.addMethod("dateFormat", function (value, element) {
        if (value === '') return true;
        return /^\d{1,2}-\d{1,2}-\d{4}$/.test(value);
    }, "Please enter a date in the format dd-mm-yyyy.");

    /**
     * Check if the radio button value is either 0 or 1
     */
    $.validator.addMethod("radioButtonBooleanValue", function (value, element) {
        return ($.inArray(parseInt(value), [0, 1]) !== -1) ? true : false;
    }, "Invalid value.");

    /**
     * Check if the url has a valid format (use this in case http/https/ftp prefix is not mandatory)
     */
    $.validator.addMethod("urlFormat", function (value, element) {
        if (value === '') return true;

        return /^((http|https|ftp):\/\/)?((www)\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,20}(:[0-9]{1,5})?(\/.*)?$/i.test(value);
    }, "Invalid url format.");

    /**
     * Validation rule that catches characters other than alpha characters
     *
     */
    $.validator.addMethod("global_name", function (value, element) {
        if (value === '') return true;

        return /^[\.\,\s/a-zA-Z0-9&_'"`-]+$/.test(value);
    }, "Must contain only letters, numbers, spaces and some of the following characters [ & . , _ ' \" ` - ]");
    //"Must contain only letters, numbers, spaces and some of the following characters [ ` ~ ! @ # $ ^ & * - = _ + \\ | ; ' : \" , . /]");

    /**
     * Validation rule for bussines and company number in investment vehicles process
     */
    $.validator.addMethod("required_buss_comp_number_in_vehicles", function (value, element, options) {
        if ($('.smsf-type-vehicles').length == 0 || ($('.smsf-type-vehicles').length == 1 && $('#corporate_trustee-yes').is(":checked"))) {
            if ($(".country-select option:selected").text() == 'New Zealand')
                if (value == '')
                    return false;
                else
                    return true;
            else if ($(options.inputclass).val() == '')
                if (value == '')
                    return false;
                else
                    return true;
            else
                return true;
        }
    }, function (param, element) {
        if ($(".country-select option:selected").text() == 'Australia')
            return 'Please inser company number or bussines';
        return 'This field is required'; //element.name.replace('_',' ') +
    });

    /**
     * Validation about email for user that should be invited
     */
    $.validator.addMethod("check_email_for_invited_users", function (value, element) {
        var ajaxreturn;
        $.ajax({
            url: globalAssetUrl + 'api/check-invate-email',
            data: {email: value},
            success: function (result) {
                ajaxreturn = true;
                $('#' + element.name + '-error').remove();
            },
            error: function (result) {
                ajaxreturn = false;
            },
            async: false
        });
        return ajaxreturn;
    }, "User with this email is already registered on our platform");

    /**
     *  Validation rule for numbers with maximum 2 decimals points
     */
    $.validator.addMethod("maxTwoDecimalsPoints", function (value, element) {
        var val = Number(value.replace(/[^0-9\.]+/g, ""));
        return /^\$?\d{0,9}(\.\d{0,2})?$/g.test(val);
    }, "Must contain max 2 decimals points.");

    /**
     * Validation rule for range values with $100,000.00 formatting
     */
    $.validator.addMethod("moneyFormatRange", function (value, element, options) {
        var val = Number(value.replace(/[^0-9\.]+/g, ""));
        return val >= options[0] && val <= options[1];
    }, function (options) {
        return "Must be within " + options[0] + " and " + options[1] + ".";
    });

    /**
     *  Validation rule for numbers with maximum 2 decimals points
     */
    $.validator.addMethod("maxDecimalsPoints", function (value, element, options) {
        var val = Number(value.replace(/[^0-9\.]+/g, ""));
        var decimals = options.decimals;
        var regexExpression = "^\\$?\\d{0,9}(\\.\\d{0," + decimals + "})?$";
        var regex = new RegExp(regexExpression, "g");
        return regex.test(val);
    }, function (options) {
        return 'Must contain max ' + options.decimals + ' decimals points';
    });

    /**
     * Validation rule for descriptions
     */
    $.validator.addMethod("description", function (value, element) {
        if (value === '') return true;
        return /[^<>{}\[\]]+$/.test(value);
    }, "This filed not allow characters like < { } [ and ]");

    /**
     * Validation rule for checking if there is a document added
     * @param  {object} sections  [sections where the user should be included, and part of the message]
     * @return {Boolean}         [true if user is included/false otherwise]
     */
    $.validator.addMethod("mandatoryOneDocument", function (value, element, params) {

        if (typeof params.class == 'undefined')
            params.class = '.up-cert-tabs-content';

        // Use this condition when one document (at least) must be included in the given section
        if ($(params.class + " .up-cert-file-blocks .up-cert-file-block").length > 0) return true;

        return false;

    }, function (params) {
        if (typeof params.msg == 'undefined')
            params.msg = 'one of the listed';
        return "Please add at least one document in " + params.msg + " sections.";
    });

    /**
     * Validation rule for checking if there is a user added to the given section/s
     * @param  {Array} sections  [sections where the user should be included]
     * @return {Boolean}         [true if user is included/false otherwise]
     */
    $.validator.addMethod("mandatoryMemberInASection", function (value, element, params) {
        var exist = 0;

        // Use this condition when one user (at least) must be included in the given section
        if (params.length == 1) {
            if ($(params + " .is-members-blocks .is-member-block").length > 0) return true;
            return false;
        }

        // Use this condition when one user (at least) must be included in one (or more) of the given sections
        $.each(params, function (key, value) {
            if ($(value + " .is-members-blocks .is-member-block").length > 0) exist = 1;
        });

        return exist;
    }, function (params) {
        if (params.length == 1) return "Please add at least one user in the Investment Team section.";
        return "Please add at least one user in one of the listed sections.";
    });

    /**
     * Validation rule for video link
     */
    $.validator.addMethod("videolink", function (value, element) {
        if (value === '') return true;
        return /^(http|https):\/\/(?:.*?)\.?(youtube|vimeo|youtu.be)\.com/.test(value);
    }, "Please insert video link from vimeo or youtube");

    /**
     * Validation rule for video link
     */
    $.validator.addMethod("exists_prefunder", function (value, element) {
        if (value === 'Yes' && $('.od-member-block').length == 0)
            return false;

        return true;
    }, "You have choose 'Yes' as answer, please add prefunder");

    $.validator.addMethod("checkTitle", function (value, element, params) {
        // return $('.sfg-input-field[name=title]')[0].value, 'Please enter a document title';
        if (typeof params.class == 'undefined')
            params.class = '.up-cert-tabs-content';

        // Use this condition when one document (at least) must be included in the given section
        if ($(params.class + " .up-cert-file-blocks .up-cert-file-block").length > 0) return true;

        return false;

    }, function (params) {
        if (typeof params.msg == 'undefined')
            params.msg = 'one of the listed';
        return "Please add at least one document in " + params.msg + " sections.";
    });
    $.validator.addMethod("checkFilename", function (value, element) {
        return $('input.file-input[name=filename]')[0].value.match(/!.(jpg|jpeg|png|gif)$/i), "Please upload a document or financial model"
    });

    /**
     * Valiation rule if element value is divisable by value of another element
     */
    $.validator.addMethod('devisable_by', function (value, element, params) {
        var price_per_share = new Decimal($(params.price_per_share).val().replace(/[^0-9\.]+/g, ""));
        var valueDecimal = new Decimal(value);
        var valueMod = valueDecimal.mod(price_per_share);
        params.extend = '';

        // If suggestion is set, extend error message with suggested value
        if ('suggestion' in params) {
            if (!valueMod.equals(0)) {
                var min_inv = new Decimal($(params.min_inv).val().replace(/[^0-9\.]+/g, ""));
                var inc_inv = new Decimal($(params.inc_inv).val().replace(/[^0-9\.]+/g, ""));
                // Modulus between input value and price per share
                var mm = new Decimal(value).minus(min_inv).mod(inc_inv);

                var roundLow = new Decimal(value).minus(mm);
                if (roundLow.lt(min_inv)) roundLow = new Decimal(min_inv);
                var roundUp = roundLow.plus(inc_inv);

                params.extend = 'You can enter $' + numberWithCommas(roundLow) + ' or $' + numberWithCommas(roundUp);
            }
        }

        return valueMod.equals(0);
    }, function (params) {
        return ' Number must select an amount divisable by: "' + params.name + '". ' + params.extend;
    });

    /**
     * Validation rule for telephone numbers
     */
    $.validator.addMethod("telephone", function (value, element) {
        // if (value === '') return true;
        if (value === '') return false;
        return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[(\)\-\s\./0-9]*$/g.test(value);
    }, "Please enter a valid phone number in any of these formats: <br>0400123456<br>0400 123 456<br>+61 (0)400 123 456");

    if (window.validationErrors) {
        openGlobalRemodal(null, 'api-investment-errors');
    }

});
/**
 * Function for remove error messages on inputs
 * @param element
 */
function removeErrorTabsOnOneFiledValidation(element) {
    var tabId = element.closest('.tabs-control-tab').attr('id');
    var $tabLink = $("a[href='#" + tabId + "'].error");
    var spanerror = $tabLink.next().text();
    if ($tabLink.length) {
        if (spanerror == 1)
            $tabLink.removeClass('error').next().text(0);

        $tabLink.next().text(spanerror - 1);
    }
}

// Ajax function to check if the user is able to invest ( faster than investment redirect )
function ajaxCanUserInvest(offerId, offerUrl){
    // if ajax returns errors set them and call the openGlobalRemodal
    $.ajax({
        url:"/api/ajaxCanUserInvest/" + offerId,
        success:function(data) {
            //console.log(data);
            if(data.errors.length > 0){
                window.validationErrors = data.errors;
                openGlobalRemodal(null, 'api-investment-errors');
            }
            else
                window.location.href = offerUrl;
        }
    });
    return false;
}

function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}
