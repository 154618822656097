$(function () {

    // Check the country and initalize the country's API
    function checkCountry() {
        var country = $('#country').val();
        switch (country) {
            case '1':
                implementAUwidget();
                break;
            case '2':
                implementNZwidget();
                break;
            case '':
                $('.address-wrapper').hide();
                $('.unknown-address').hide();
                $('.search-address').hide();
                $('#street_search').hide();
                break;
            default:
                showExpandedAddress();
                $('#city').show();
                $('#suburb').hide();
                $('#state_territory').hide();
                $('.unknown-address').hide();
                $('.search-address').hide();
                break;
        }
    }

    function implementAUwidget() {
        // Not needed in Australia as we are already asking "suburb"
        $('#city').hide();
        $('#suburb').show();
        $('#state_territory').show();
        showSearchAddress();

        $("#street_search").keyup(function (e) {
            var query = $("#street_search").val();
            if (query !== undefined && $('#country').val() === "1") {
                addressLookup('auAddressLookup', query, 'Australia');
            }
        });
    }

    function implementNZwidget() {
        $('#city').show();
        $('#suburb').hide();
        $('#state_territory').hide();
        showSearchAddress();

        $("#street_search").keyup(function (e) {
            var query = $("#street_search").val();
            if (query !== undefined && $('#country').val() === "2") {
                addressLookup('nzAddressLookup', query, 'New Zealand');
            }
        })
    }

    function addressLookup(type, query, country) {
        $.ajax({
            url: window.location.origin + '/api/address-lookup',
            type: 'post',
            data: {type: type, query: query},
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            success: function (data) {
                if (data.error) {
                    return console.error('Error:', data.error);
                } else if (data) {

                    // First lookup call for NZ
                    if (data.completions) {
                        var results = {nzResults: data.completions};
                        $("#street_name").trigger('populate', results);
                    } else if (data.payload) {
                        var ausResults = [];
                        for (var i = 0; i < 10; i++) {
                            var payload = data.payload[i];
                            if (payload) {
                                ausResults.push({
                                    label: payload.fullAddress,
                                    streetNumber: (payload.subdwelling ? payload.subdwelling + ' ' : '') + payload.streetNumber,
                                    streetName: payload.streetName,
                                    streetType: payload.streetType,
                                    locality: payload.locality,
                                    state: payload.state,
                                    postcode: payload.postcode,
                                    postal: payload.postal
                                });
                            }
                        }
                        // When finished send results
                        $("#street_name").trigger('populate', {ausResults: ausResults});

                    } else {
                        // Second call to extract address detailed data for NZ address
                        saveToModel(_.assign(data, {country: country}));
                    }

                } else {
                    return console.error('Data was empty');
                }
            },
            error: function (error) {
                return console.error('error', error);
            }
        });
    }

    function showExpandedAddress() {
        $('#street_search').hide();
        $('.unknown-address').hide();
        $('.address-wrapper').show();
        $('.search-address').css('display', 'inline-block');
    }

    function showSearchAddress() {
        $('#street_search').show();
        $('.unknown-address').show();
        $('.address-wrapper').hide();
        $('.search-address').hide();
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function saveToModel(selectedAddress) {
        if (selectedAddress.country === 'New Zealand') {
            var streetType = capitalizeFirstLetter(selectedAddress.street_type);
            var streetName = selectedAddress.street.substring(0, selectedAddress.street.lastIndexOf(streetType)).trim();

            $('#street_number').val(selectedAddress.number).trigger('keyup');
            $('#street_name').val(streetName).trigger('keyup');
            $('#street_type').val(streetType).trigger('keyup');
            $('#suburb').val(selectedAddress.suburb);
            $('#city').val(selectedAddress.city);
            $('#state_territory').val(selectedAddress.region);
            $('#postcode').val(selectedAddress.postcode);
        }

        if (selectedAddress.country === 'Australia') {
            var streetName = selectedAddress.streetname ? selectedAddress.streetname : selectedAddress.postal;

            $('#street_number').val(selectedAddress.streetnumber).trigger('keyup');
            $('#street_name').val(streetName).trigger('keyup');
            $('#street_type').val(selectedAddress.streettype).trigger('keyup');
            $('#suburb').val(selectedAddress.locality);
            $('#state_territory').val(selectedAddress.state);
            $('#postcode').val(selectedAddress.postcode);
        }
        // Open full address block if street name is empty
        if (!streetName) {
            $('.address-wrapper').show();
            $('.search-address').show();
            $('.unknown-address').hide();
            $('#street_search').hide();
        }
    }

    function mapAddressResults(data) {
        // Make the element to start the address results list

        var arList = '<ul class="addResults">';

        if (data.ausResults && data.ausResults.length >= 1) {
            data.ausResults.map(function (result) {
                arList += '<li class="addResults-li" data-streetNumber="' + result.streetNumber + '" ';
                arList += 'data-streetName="' + result.streetName + '" ';
                arList += 'data-streetType="' + result.streetType + '" ';
                arList += 'data-locality="' + result.locality + '" ';
                arList += 'data-state="' + result.state + '" ';
                arList += 'data-postal="' + result.postal + '" ';
                arList += 'data-postcode="' + result.postcode + '" ';
                arList += 'data-country="Australia">' + result.label + '</li>';
            });
        } else {
            $('.addResults').remove();
        }

        if (data.nzResults && data.nzResults.length >= 1) {
            data.nzResults.map(function (result) {
                arList += '<li class="addResults-li" data-id="' + result.pxid + '" data-country="New Zealand">' + result.a + '</li>';
            });
        } else {
            $('.addResults').remove();
        }

        arList += '</ul>';

        $('.addressDataResults').append(arList);
    }

    function initiateSearchAddress() {
        var address_details = [$('#street_number').val(), $('#street_name').val(), $('#street_type').val(), $('#suburb').val(), $('#city').val(), $('#state_territory').val(), $('#postcode').val()]
            .filter(function (word) {
                return typeof word != "undefined" && word.trim() != '';
            })
            .join(' ');

        $('#street_search').val(address_details);
    }

    //******************************************************************** */
    // Event listeners
    //******************************************************************** */

    $(document).on('change', '#country', function () {
        $("input.address-field").val("");
        $("select.address-field").val("");
        checkCountry();
    });

    // When the user clicks on the "can't find your address" link
    $('.unknown-address').click(showExpandedAddress);

    // When the user wants to search for their address
    $('.search-address').click(showSearchAddress);

    // Event listener for when it populates with address results
    $('#street_name').on('populate', function (e, data) {
        mapAddressResults(data);
    });

    // Clear results when the user clicks anywhere on the screen
    $(document).click(function (e) {
        $('.addResults').remove()
    });

    // Event listener for dynamic list element
    $(document).on('click', 'li.addResults-li', function (e) {
        if (e.target.dataset.country === 'New Zealand') {
            var country = e.target.dataset.country;
            var selectedId = e.target.dataset.id;
            addressLookup('nzAddressSpecific', selectedId, country);
        }

        if (e.target.dataset.country === 'Australia') {
            // Fill in details from clicked element and pass through to the server
            saveToModel(e.target.dataset);
        }

        $('#street_search').val(e.target.innerText);
        $('#autocomplete_used').val(e.target.innerText);
    });


    //****************************/
    // Start application logic
    //****************************/
    $(window).load(function () {
        initiateSearchAddress()
        checkCountry();
    });

});
