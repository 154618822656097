$(document).ready(function() {

    $.ajaxSetup({
        headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    window.addEventListener('scroll', function (e) {
        $('.bounce').fadeOut(3000);
    });
    window.addEventListener('scroll', function (e) {
        $('.scroll-bounce').fadeOut(3000);
    });

    // Allow elements with a data-href attribute to act kind of like a link
    $('*[data-href]').click(function (e) {
        window.location.href = $(this).attr('data-href');
    });


    // Set height of Register & Login left and right columns
    function setHeight() {
        leftcolHeight = $('.reg-log-leftcol').innerHeight();
        $('.reg-log-rightcol').css('min-height', leftcolHeight);
    };

    if ($(window).width() > 868) {
        setHeight();
    } else {
        $('.reg-log-rightcol').css('min-height', 'auto');
    }

    $(window).resize(function() {
        if ($(window).width() > 868) {
            setHeight();
        } else {
            $('.reg-log-rightcol').css('min-height', 'auto');
        }
    });

    // Open mobile navigation
    $('.open-nav-btn').on("click", function(e){
        e.stopPropagation();
        $('.header-navigation').stop().slideToggle();
        $(this).toggleClass('active');
    });

    $(document).on('click touch', function(e) {
      e.stopPropagation();
      if ($('.open-nav-btn').hasClass('active'))
       $('.header-navigation').css("display", "none")
       $('.open-nav-btn').removeClass('active');
     });

    // Switch between user profile tabs content
    $('.up-tabs > li > a').on("click", function(e){
        e.preventDefault();

        var upTabId = $(this).attr("id");

        $('.up-tabs > li > a').removeClass('active');

        if($(this).is('#' + upTabId)) {
            $('.up-tabs-content > div').hide();
            $('.up-' + upTabId + '-content').fadeIn(700);
            $(this).addClass('active');
        }
    });

    // Switch between user profile certificate content tabs
    $('.up-cert-tabs > li > a').on("click", function(e){
        e.preventDefault();

        var upCertTabId = $(this).attr("id");

        $('.up-cert-tabs > li > a').removeClass('active');

        if($(this).is('#' + upCertTabId)) {
            $('.up-cert-tabs-content > div').hide();
            $('.up-cert-' + upCertTabId).fadeIn(700);
            $(this).addClass('active');
        }
    });

    $(".is-director-checkbox").change(function(){

        if($(this).val() == "Yes"){
            $('.if-director-error').hide();
            $('.is-table-container').hide();
            $('.is-newcompany-form').fadeIn();
        }else{
            $('.if-director-error').show();
            $('.is-newcompany-form').fadeOut();
        }
    });

    // Switch between investment settings company members tabs
    $('.is-cm-tabs > li > a').on("click", function(e){
        e.preventDefault();

        var isMembersTabId = $(this).attr("id");

        $('.is-cm-tabs > li > a').removeClass('active');

        if($(this).is('#' + isMembersTabId)) {
            $('.is-cm-tabs-content > div:not(.is-addnew-user)').hide();
            $('.is-' + isMembersTabId).fadeIn(700);
            $(this).addClass('active');
        }
    });

    $('#passport_expiry_date').datepicker({ dateFormat: 'dd-mm-yy', changeMonth: true, changeYear: true, yearRange: "-0:+50", onChangeMonthYear: function (year, month, inst) {
        var curDate = $(this).datepicker("getDate");
        if (curDate == null) return;
        if (curDate.getYear() != year || curDate.getMonth() != month - 1) {
            curDate.setYear(year);
            curDate.setMonth(month - 1);
            $(this).datepicker("setDate", curDate);
        }
    }});

    // Aannouncements bar controll
    $('#main-header-notification > div > a').click(function () {
        $('#main-header-notification').hide();
    });
    var visits = Cookies.get('_eq_visits') || 0;
    visits++;
    Cookies.set('_eq_visits', visits, { expires: 3, path: '/' });
    if (window.location.pathname === '/') {
        $('#main-header-notification').css('display', 'block');
    }

    var toggleDropdown = function(e, toggleSelector) {
        var allMenus = ['.header-logged-user-dropdown', '.header-logged-notification-dropdown', '.header-nav-dropdown'];
        var keepIndex = allMenus.indexOf(toggleSelector);
        if(keepIndex > 0){
            allMenus.splice(keepIndex, 1);
        }
        var $hideEl = $(allMenus.join(", "));
        var $toggleEl = $(toggleSelector);
        $hideEl.stop().slideUp();
        $toggleEl.stop().slideToggle();
        e.stopPropagation();
    };

    //JS cript for dropdown in menu when user is loggedin
    $('.header-logged-user').click(function(e){
        toggleDropdown(e, '.header-logged-user-dropdown');
        var $openNavBtn = $('.open-nav-btn');
        if($openNavBtn.hasClass("active")){
            $openNavBtn.removeClass('active');
            $(".header-navigation").slideUp();
        }
    });
    $('.header-logged-notification > a').click(function(e){
        toggleDropdown(e, '.header-logged-notification-dropdown');
    });
    // Toggle main navgiation dropdown for 'MORE'
    $('.header-nav-more').on("click", function(e){
        toggleDropdown(e, '.header-nav-dropdown');
    });
    // Hide all menus on click outside
    $(document).click(function(e) {
        toggleDropdown(e); // hides all menus
    });

    var yearRange = "-99:+99";
    if($('.settings-container').length > 0)
        yearRange = "-100:+0";

    $('.datepicker_input').datepicker({ dateFormat: 'dd-mm-yy', changeMonth: true, changeYear: true, yearRange: yearRange, onChangeMonthYear: function (year, month, inst) {
        var curDate = $(this).datepicker("getDate");
        if (curDate == null) return;
        if (curDate.getYear() != year || curDate.getMonth() != month - 1) {
            curDate.setYear(year);
            curDate.setMonth(month - 1);
            $(this).datepicker("setDate", curDate);
        }
    }});

    // Remove the error on the file input and show the file name instead the span/placeholder
    $(document).on("change", "input:file", (function(){
        if($(this).val() != ""){
            $(this).parent().parent().children("span").remove();
            $(this).parent().children("span").text(this.value.replace(/C:\\fakepath\\/i, ''));
        }
    }));

    /**======================
        Tabs control
    ======================**/

    // Tab change event
    $('ul.tabs-control li').on('click', 'a:not(.active)', function(e) {
        //hide div for invite user
        $('.invite-user-form').hide();
        $('.invited_email').val('');

        e.preventDefault();
        var selector = $(this).attr('href');
        $(this).closest('ul').find('.active').removeClass('active');
        $(this).addClass('active');
        $(selector).addClass('active').siblings('.tabs-control-tab').removeClass('active');
    });

    // Set the second li tab as active (for edit syndicate process only)
    $firstTab = $('ul.tabs-control li:first-child a');
    $firstTab.addClass('active');
    $($firstTab.attr('href')).addClass('active');

    /**======================
        Team members control
    ======================**/
    // Add team member
    $('.add-team-member').click(function(e) {
        e.preventDefault();
        var teamSelect = $(".team-tags-style:visible");

        if( $('.investment-vehicles-team').length > 0 )
            addMultipleTeamMembers(teamSelect,'div.is-members-blocks', 'Selected user already exist as director or shareholder');
        else
            addMultipleTeamMembers(teamSelect);
    });

    $('.is-members-blocks').on('click', 'a.is-member-block-remove', function(e) {
        e.preventDefault();

        if($(this).hasClass('remove-existed')){
            $(this).closest('.is-member-block').addClass('remove-member existing-user');
        }
        else
            $(this).closest('.is-member-block').addClass('remove-member');

        // Confirmation modal for team member removal
        openGlobalRemodal("Do you really want to remove this member from the team?", "member-action-modal", false);
    });

    $(document).on('closing', '.remodal.member-action-modal', function (e) {
      if(e.reason == 'confirmation')
      {
        if($('.remove-member').hasClass('existing-user'))
        {
            var dataSend = {
                teamuser: $('.remove-member .remove-existed').attr('data-teamuser'),
                roleid: $('.remove-member .remove-existed').attr('data-roleid'),
                entityid: $('.remove-member .remove-existed').attr('data-entityid')
            };
            var dataType = $('.remove-member .remove-existed').attr('data-sectiontype');

            //should be added dataType on the link, with value for example: vehicles, project, syndicate..
            removeUserTeam(dataType, dataSend);
        }
        else
          $('.remove-member').remove();
      }
      else
        $('.remove-member').removeClass('remove-member');
    });

    // Transfer multuple team members from select box to real inputs
    var addMultipleTeamMembers = function(select, findindivDefault, errormsgDefault ) {

        findindiv = findindivDefault || 'div.is-members-blocks:visible';
        errormsg = errormsgDefault || 'User is already added to this section.';

        var users = select.select2('data'), role = select.data('role');
        if (users.length > 0) {
            // Transfer all option from the select box to container
            $.each(users, function(key, user) {
                if(!$(findindiv).find('input:hidden[value="'+user.id+'"]').length){
                    addTeamMemberBlock(user, role, "div.is-members-blocks:visible");
                }else{
                    openGlobalRemodal(errormsg, "team-member-added-confirmation-modal");
                }
            });
            // Clear all options after selection
            select.select2('val', '');
        }
    };

    //when invite new user is clicked
    $(document).on('click','.invite-team-user',function(e){
        e.preventDefault();
        $('.invite-user-form, .is_add_new_user').fadeIn('slow');
        $('.remove-on-invest').fadeOut('fast');
    });

    $('.remove-block-for-invite').click(function(){
        hideInviteUserForm();
    })

    //trust radio button clicked
    $('.ct-radio-trustee').change(function(){

        if($('#corporate_trustee-yes').is(":checked")) {
            $('.selected-corporate-trustee').show();
        }else{
            $('.selected-corporate-trustee').hide();
            $('.selected-corporate-trustee input').val('');
        }
    });

    //global links for page html info popups
    $('.popup-info').click(function(e){
        e.preventDefault();
        var classmodal = $(this).attr('data-remodal');
        $('[data-remodal-id=' +classmodal+ ']').remodal({ closeOnEscape : false}).open();
    });

    $('#portfolio-company-amount').autoNumeric('init',{aSign:'$', unformatOnSubmit: true});
    $('#portfolio-company-amount').on('keyup',function(e){
        $(this).autoNumeric('set',$(this).val());
    });

    // Scroll to div on Home page and Syndicates Landing page
    $('.home-down-arrow').click(function(event) {
        $('html, body').animate({
            scrollTop: $(".reache-cont-onscroll").offset().top
        }, 1500);
    });

    $('.scroll_down').click(function(event) {
        $('html, body').animate({
            scrollTop: $(".reache-cont-onscroll").offset().top
        }, 1500);
    });

    // $('a.home-invest-btn, a.go-to-invest').click(function(event) {
    //     $('html, body').animate({
    //         scrollTop: $(".reache-cont-onscroll").offset().top
    //     }, 1500);
    // });
});

/**
 * Invite team user with given email address
 * @param {Object} element     [description]
 * @param {String} role        [user's role]
 * @param {String} visible_tab [description]
 */
function addInvitedUser(element, role, visible_tab) {

    visible_tab = visible_tab || '';

    if($(visible_tab+".invited_added[value='"+element.val()+"']").length == 0 && $(visible_tab+'input:hidden[data-check-invited="'+element.val()+'"]').length == 0)
    {
        var $userBlock = $(".is-member-block.default").clone();
        $userBlock.removeClass('default');

        $userBlock.find('span.member-name').text(element.val())
        .next().text('Investment Team')
        .next().next().attr('name', 'member_invited['+role+'][]').attr('value', element.val()).attr('class','invited_added');

        hideInviteUserForm();

        $userBlock.show().appendTo("div.is-members-blocks:visible");
    }else{
        openGlobalRemodal('User is already added to this section.', 'team-member-added-confirmation-modal');
    }
}

/**
 * Clean an existing errors & hide "invite user" form
 * @param  {String} element    [class/id of the input that should be removed]
 */
function hideInviteUserForm(element) {
    element = element || 'invited_email';
    $('.invite-user-form').fadeOut('fast');
    $('.remove-on-invest').fadeIn('slow');
    $('input[name='+element+']').val('');
    $('#'+element+'-error').remove();
}

/**
 * Remove a user from
 * @param  {string} type     [description]
 * @param  {[type]} jsondata [description]
 * @return {[type]}          [description]
 */
function removeUserTeam(type, jsondata) {
    $.ajax({
        url: globalAssetUrl+'api/remove-team-user/'+type,
        data: jsondata,
        type: "POST",
        async: false,
        success: function (result) {
          $('.remove-member').remove();
        },
        error: function(result){
           openGlobalRemodal('Unexpected error happened! Please, try again later.');
        }
    });
}

   /**
    * Add proper id and message to the remodal confirmation box and open it
    * @param  {String} message          [message used for the remodal box]
    * @param  {String} remodal_id       [id applied to the remodal box]
    * @param  {String} remodal_options  [class of the remodal that should be called]
    */
    var $offerInfoModal = $('[data-remodal-id=remodal-global-info-modal]').remodal({ closeOnEscape : true, closeOnOutsideClick : true });
    var $offerChoiceModal = $('[data-remodal-id=remodal-global-choice-modal]').remodal({ closeOnEscape : true, closeOnOutsideClick : true });
    window.openGlobalRemodal = function(message, target, info, vueInstance) {

        target = target || 'default-target-class';
        info = info || true;

        if (target === 'submitting') {
            var $modal = $('[data-remodal-id=submitting]');
            var modalContent = "<div class='submitting-container'><h2>Submitting</h2><div id='circleG'><div id='circleG_1' class='circleG'></div><div id='circleG_2' class='circleG'></div><div id='circleG_3' class='circleG'></div></div></div>";

            $modal[0].innerHTML = modalContent;
            return $modal.remodal({ closeOnEscape : false, closeOnOutsideClick : false });
        }

        if (target === 'partner-subscribe') {
            var $modal = $('[data-remodal-id=submitting]');
            $modal[0].innerHTML = "<div class='partner-subscribe-container'><img class='exclamation-mark' src='/images/happy-face.png' /><h2>You have been subscribed!</h2><button data-remodal-action='confirm' class='remodal-confirm close'>Close</button></div>";
            return $modal.remodal().open();
        }

        if (target === 'api-investment-errors') {
            var $modal = $('[data-remodal-id=errors-validation]');
            var errorItems = '';
            var suggestedAmount = null;
            var errors = window.validationErrors || message; // Gets error either from blade via validationErrors var or via api with message

            errors.forEach(function(el) {
                errorItems += '<div class="my-modal-item">'
                errorItems += '<img class="icon" src="/images/cross-circle.png" width="35" height="35" />'
                errorItems += '<p class="modal-p">' + el.msg + '<br />'
                errorItems += (el.link === '#' ? '' : '<a class="modal-link" href="' + el.link + '">' + el.linkText + '</a>')
                errorItems += "</p></div>";
                if(el.suggestedAmount)
                    suggestedAmount = el.suggestedAmount;
            });

            var modalPrepend = '<h2 class="modal-heading">You’re so close...</h2>';
            modalPrepend += '<div class="my-modal-container">' + errorItems + '</div>';

            if (suggestedAmount) {
                modalPrepend += '<div class="modal-buttons-footer">'
                modalPrepend += '<button data-remodal-action="confirm" class="remodal-confirm close">Update investment amount to $' + suggestedAmount.toLocaleString() +'</button>';
                modalPrepend += '<button data-remodal-action="cancel" class="remodal-confirm cancel">Cancel</button>';
                modalPrepend += '</div>'
            } else
                modalPrepend += '<button data-remodal-action="cancel" class="remodal-confirm close">Close</button>';

            $modal[0].innerHTML = modalPrepend;

            // This is only for the invest process
            $(document).on('confirmation', '.remodal.api-investment-errors', function(e) {
                vueInstance.updateToSuggestedAmount(suggestedAmount);
            });
            return $modal.remodal().open();
        }

        if (target === 'ipo-fsg-prospectus') {
            var $modal = $('[data-remodal-id=ipo-fsg-prospectus]');
            $modal[0].innerHTML = '<div class="au-investor notice">Australian investors must confirm that they have read and <br/>understood the <a href="/financial-service-guide" target="_blank">Financial Service Guide</a>.</div> <div class="notice">All investors must acknowledge that they have downloaded and <br/>read the <a href="https://equitise.com/files/offer-documents/xiwe7eQwDYTyUoTeqFHp4zYys9ZJWoRlrpaXPIlQ.pdf" target="_blank">Prospectus</a></div><div style="text-align: center"><button data-remodal-action="confirm" class="remodal-confirm close">I AGREE</button></div>';
            return $modal.remodal().open();
        }

        // Add class attribute
        var modal = info ? $offerInfoModal : $offerChoiceModal;
        $(modal.$modal.context).addClass(target);

        $('.' + target).children('p').html(message);
        modal.open();
    }

    $(document).on('closed', '.remodal.remodal-global-info-modal, .remodal.remodal-global-choice-modal', function (e) {
        $(e.currentTarget).attr('class', function (index, css) {
            return (css.match (/(^|\s)remodal?\S+/g) || []).join(' ');
        });
    });

// Call investment settings datatables
$('#is-companies-table').DataTable({
    bFilter: false,
    bInfo: false,
    paging:   false,
    ordering: false,
});
$('#id-companies-table').DataTable({
    bFilter: false,
    bInfo: false,
    paging:   false,
    ordering: false,
});
$('#cr-companies-table').DataTable({
    bFilter: false,
    bInfo: false,
    paging:   false,
    ordering: false
});

function loadTinyMCEEditor() {
    tinymce.init({
        selector:'.co-editor',
        theme:'modern'
    });
}

function cacheAutocomplete(cache,term,response){

    $.each(cache, function(key, data){

        if (term.indexOf(key) === 0 && data.length > 0) {
            foundInCache = true;

            if(data.length == 1 && data[0].value == "No results found"){
                $("#company_id").val("");
                response(data);
                return;
            }else{
                if(($.ui.autocomplete.filter(data, term)).length == 0){

                    $("#company_id").val("");
                    availableResults.push({label: "", value: "No results found"});
                    response(availableResults);
                    return;
                }else{
                    response($.ui.autocomplete.filter(data, term));
                    return;
                }
            }
        }
    });
}

function addTeamMemberBlock(user, role, divappend){

    var $userBlock = $(".is-member-block.default").clone();
    $userBlock.removeClass('default');
    $userBlock.find('img').attr('src', user.image).addClass('YES');
    $userBlock.find('span.member-name').text(user.text);
    $userBlock.find('.member-position-title').html(user.position == '' ? '&nbsp' : user.position);
    $userBlock.find('div > div > a').attr('href',  globalAssetUrl+'profile/'+user.profile_slug);
    $userBlock.find('input').attr('name', 'member['+role+'][][user_id]').attr('value', user.id);
    $userBlock.show().appendTo(divappend);
}

/**
* Create slug from string
* @param string text
* @return string
*/
function convertToSlug(Text)
{
    return Text
        .toLowerCase()
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-')
        ;
}
