$(document).ready(function() {

    /* SHOW/ HIDE ADDITIONAL FIELDS */
    $('input.radios, .contingent-radios input[type=radio]').on('change', function(){
        var $elem = $('#'+$(this).attr('for'));
        var value = $(this).val();
        $elem.toggle(value == 'yes' || value == 1);
    });

    $('input[name=referer_exists]').on('change', function() {
        var value = $(this).val();

        if (value == 0) {
            $('#refer-absent').css( "display", "table" )
        } else {
            $('#refer-absent').css( "display", "none" )
        }

    });

    /* GET ALL COMPANIES AND POPULATE THEM IN AN AUTOCOMPLETE */
    var cache = {};

    if (document.getElementById('questionnaire-company-select') !== null) {

        $("#questionnaire-company-select").autocomplete({
            delay: 300,
            minLength: 3,
            source: function (request, response) {

                $("#loading").addClass("isloading");

                var availableResults = [], term = request.term.toLowerCase(), foundInCache  = false;

                // $.each(cache, function(key, data){
                //     if (term.indexOf(key) === 0 && data.length > 0) {
                //         foundInCache = true;
                //
                //         if(data.length == 1 && data[0].value == "No results found"){
                //             $("#company_id").val("");
                //             response(data);
                //             return;
                //         }else{
                //             if(($.ui.autocomplete.filter(data, term)).length == 0){
                //                 $("#company_id").val("");
                //                 availableResults.push({label: "", value: "No results found"});
                //                 response(availableResults);
                //                 return;
                //             }else{
                //                 response($.ui.autocomplete.filter(data, term));
                //                 return;
                //             }
                //         }
                //     }
                // });
                //
                // if (foundInCache) return;

                $.getJSON(globalAssetUrl + "api/companies/" + term,  function (data) {

                    // Remove old results
                    availableResults.length = 0;
                    $(".ui-autocomplete").empty();

                    if(data.length === 0){
                        $("#company_id").val("");
                        availableResults.push({label: "", value: "No results found"});
                    }else{
                        $.each(data, function (i, item) {
                            availableResults.push({
                                label: item.text,
                                logo: item.image,
                                identifier: item.id,
                                url: typeof item.social_links[0] != "undefined" && typeof item.social_links[0].url != "undefined" ? item.social_links[0].url : '',
                            });
                        });
                    }

                    cache[term] = availableResults;
                    response(availableResults);
                });                     
            },
            open: function(event, ui) {
                $('.ui-autocomplete').append('<li class="add_new_wbi"><span class="new-company-autocomplete">Can\'t find the company? Click <a href="/company/new?redirect=' + window.location.pathname + '">here</a> to add it</span></li>');
                // Adjust the dynamic ul to match same width of its input
                $('.ui-autocomplete').width($('.ui-autocomplete-input')[0].clientWidth);
        },
            focus: function (event, ui) {
                $("#" + $(this)[0].id).val(ui.item.label);
                $("input[name='company_url']").val(ui.item.url);
                return false;
            },
            select: function (event, ui) {
                $("#" + $(this)[0].id).val(ui.item.label);
                $("input[name='company_url']").val(ui.item.url);
                $("#company_id").val(ui.item.identifier);
                $(".q-form-group-hidden").hide(); // add display: none;
                return false;
            },
            change: function (event, ui) {
                // If no item from the autocomplete list was selected
                if (ui.item == null)
                    $("input[name='company_url'], #company_id").val("");
            },
            close: function (event, ui) {
                $("#questionnaire-company-select").show();            
                return false;
            },
            response: function(event, ui) {
            $("#loading").removeClass("isloading");
        }
        }).data("ui-autocomplete")._renderItem = myRenderFunc;
        /* GET ALL COMPANIES AND POPULATE THEM IN AN AUTOCOMPLETE */

        /**
        * Client side validation rules for the Questionnaire
        */
        var questionnaireForm = $('#deal-creation-questionnaire').validate($.extend({}, settings, {
            ignore: [],
            rules: {
                company_name: { required: true, alphaNumberWithSpaceHyphenDashCommaQuote: true },
                company_id: { required: function(element) {
                    return $('#questionnaire-company-select').val() != "";
                }},
                description: { required: true },
                company_stage: { required: true },
                director_loans: { required: true, radioButtonBooleanValue: true },
                business_orientation: { required: true, radioButtonBooleanValue: true },
                raise_amount: { required: true },
                premoney_valuation: { required: true },
                assets_value: { required: true },
                annual_revenue: { required: true },
                equity: { required: true },
                crowndfunding_previously: { required: true, radioButtonBooleanValue: true },
                previous_raise: { required: "#crowndfunding-previously-yes:checked" },
                prefunders: { required: true, radioButtonBooleanValue: true },
                document_exists: { required: true, radioButtonBooleanValue: true },
                deal_document: { required: "#document-exist-yes:checked", extension: "jpeg,bmp,png,pdf,doc,docx,xlsx,xls,xlt,xltx,ppsx,pps,pptx,ppt" },
                fraud: { required: true, radioButtonBooleanValue: true },
                referer_exists: { required: true, radioButtonBooleanValue: true },
                referrer: { 
                    required: function(element) {
                        return $('input[name=referer_exists]:checked').val() == '1'
                    }
                },
                referrer_options: {
                    required: function(element) {
                        return $('input[name=referer_exists]:checked').val() == '0'
                    }
                }
            },
            messages: {
                company_id: {
                    required: "Please select an existing company, or create a new one."
                }
            }
        }));

    }
    });

// Use this method after assigning the autocomplete control.
function myRenderFunc(ul, item){

    if (item.value !== "No results found") {
        $(ul).addClass("custom-autocomplete company-autocomplete");
        $(ul).removeClass("noresults-autocomplete");
        $(ul).removeAttr('style');

        return $("<li class='ui-menu-item' role='presentation'>")
                .data("ui-autocomplete-item", item)
                .append('<a class="ui-corner-all" tabindex="-1"><img class="autocomplete-pr-image" src="' + item.logo + '" alt="" />'+
                        '<div class="autocomplete-pr-name">' + item.label + '</div>')
                .appendTo(ul);
    } else {
        $(ul).addClass("custom-autocomplete noresults-autocomplete");
        $(ul).removeAttr("style");
        return $("<li></li>"); 
    }  
 }